export enum SORTING_ORDER {
  ASC = 'asc',
  CUSTOM = 'custom',
  DESC = 'desc',
  NONE = 'none',
}

export enum ORDER_BY {
  ViewName = 'ViewName',
}

export const ACCESS_DRAWER_TOP_POSITION = '-8px';
export const CLONE_SUCCESS_MESSAGE = 'View cloned successfully.';
export const BODY_SKELETON_WIDTH = '20px';
export const FULL_WIDTH = '100%';
export const DEFAULT_DRAWER_TOP_POSITION = 43;
export const DEFAULT_SKELETON_NUMBER_OF_ROWS = 5;
export const TOOLTIP_MAX_WIDTH = 200;
export const VIEW_NAME_NOT_BLANK = 'View name cannot be blank.';
export const VIEW_NAME_MAX_LENGTH_ERROR_MESSAGE = 'View name must not exceed 200 characters.';
export const VIEW_NAME_MIN_LENGTH_ERROR_MESSAGE = 'View name must be at least three characters, excluding spaces.';
export const DEPARTMENTS_ERROR_MESSAGE = 'Please select at least one department.';
export const TEMPLATES_ERROR_MESSAGE = 'Please select at least one template.';
export const VIEW_EDITOR_SELECT_WIDTH = '250px';

export const TOOLTIPS = {
  AutoAddAssignments:
    'When Auto-Add Assignments is enabled, all assignments added to any template included in this view will be added to the view.',
  AutoAddPersonnel:
    'When Auto-Add Personnel is enabled, all scheduled personnel added to any department included in this view will be added to the view.',
  CompactMode:
    'When Display Personnel and Assignment Compact Names is enabled, personnel and assignments are displayed with compact names in the view. When Display Personnel and Assignment Compact Names is disabled, regular display names are used.',
  CondenseColumnToFitScreen:
    'When Condense Columns to Fit Screen is enabled, the view will condense in size to fit the user’s browser, allowing more information to display without the need to scroll.',
  DisplayDepartmentNames:
    'If Department Name is selected, the Department name will be displayed in the view. If Department Compact Name is selected, the Department Compact Name will be used. If Not Displayed is selected, the department name will not be shown.',
  EnableSaveFilters:
    'Saved Filters allow users to filter a view by personnel and assignments and then save the configuration for quick use later on, rather than re-creating the filters each time the view is opened. Each user can create up to ten filters per view.',
  HideBlankRows:
    'When Hide Blank rows is enabled, any assignment (if grouped by assignment) will be hidden if there are no personnel working it in the row. Any personnel (if grouped by personnel) will be hidden if they do not work an assignment in the row.',
  HideInactiveAssignments:
    'When Hide Inactive Assignments is enabled, all assignments that end prior to today will not be shown.',
  HideInactivePersonnel:
    'When Hide Inactive Personnel is enabled, all personnel whose license ends prior to today will not be shown.',
  HidePendingSlots: 'When Hide Pending Slots is enabled, the list view will only show finalized schedule changes.',
  HideWeekends: 'When Hide Weekends is enabled, only Monday-Friday is shown in the view.',
  ShowLoggedInUserOnly:
    'When Show Logged in User Only is enabled, views only display the schedule information for the current user. If the current user is unscheduled, the view will be empty.',
  ShowTimes: 'When Show Times is enabled, slots will include the time of the assignment.',
  TalliesCountPersonnelBasedOnFilter:
    'When Tallies Count Personnel Based on Filter is enabled, tallies included in the view only count up personnel included in the filter.',
};

const UIConstants = {
  BODY_SKELETON_WIDTH,
  CLONE_SUCCESS_MESSAGE,
  DEPARTMENTS_ERROR_MESSAGE,
  FULL_WIDTH,
  ORDER_BY,
  SORTING_ORDER,
  TEMPLATES_ERROR_MESSAGE,
  TOOLTIPS,
  VIEW_NAME_MAX_LENGTH_ERROR_MESSAGE,
  VIEW_NAME_MIN_LENGTH_ERROR_MESSAGE,
  VIEW_NAME_NOT_BLANK,
};

export default UIConstants;
