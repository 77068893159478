/* eslint-disable camelcase */
import { DateTime } from 'luxon';

import { BooleanYesNo } from '@/constants/enums';
import { PaginationFilter } from '@/types/api.types';
import { PersonnelViewAccessBody, PersonnelViewMembership } from '@/types/personnel.types';
import { CellColor, DaysOfWeekAbbreviationFromFullString, PersonnelSortOrderDropDownOptions } from '@/types/ui.types';
import View, {
  GridSettingsLeftColumnType,
  ViewColoredItem,
  ViewEditorDisplayDepartmentNames,
  ViewEditorDraftSortByAssignmentOptions,
  ViewEditorDraftSortByPersonnelOptions,
  ViewLayoutDataType,
  ViewLayoutDisplayRange,
  ViewLayoutType,
} from '@/types/view.types';

export const ALPHANUMERIC_TABLE_SORT = 'alphanumeric';

export const paginationDefault: PaginationFilter = {
  PageIndex: 0,
  PageNumber: 1,
  PageSize: 50,
  TotalPages: 0,
  TotalRecords: 0,
};

export enum ToastTypes {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
}

export enum COOKIE_NAMES {
  DEV_BEARER = 'DEV_BEARER',
  REFRESH_TOKEN = 'LB_TKN',
}

export const DEFAULT_INVALID_INTEGER = -1;
export const DEFAULT_TOAST_DURATION = 4000;
export const DEFAULT_PERSONNEL_ID = DEFAULT_INVALID_INTEGER;

export const DEFAULT_CREATE_SUCCESS_MESSAGE = 'View saved successfully.';
export const DEFAULT_UPDATE_SUCCESS_MESSAGE = 'Changes saved successfully.';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const CLONE_VIEW_NAME_EXISTS_ERROR_MESSAGE = 'View with this name already exists';
export const DEFAULT_ARRAY_START_INDEX = 0;
export const EMPTY_ARRAY_LENGTH = 0;
export const DEFAULT_ONE_ELEMENT_ARRAY_LENGTH = 1;
export const DEFAULT_ARRAY_ELEMENT_DELETE_COUNT = 1;
export const DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND = -1;
export const DEFAULT_STRING_ARRAY_COMPARE_ASCENDING = -1;
export const DEFAULT_STRING_ARRAY_COMPARE_DESCENDING = 1;

export const TOKEN_NAME = 'unity_token';

export const DEFAULT_CELL_COLOR_OPTIONS: CellColor[] = [
  {
    cellColor: '#c44601',
    textColor: '#ffffff',
  },
  {
    cellColor: '#f57600',
    textColor: '#000000',
  },
  {
    cellColor: '#8babf1',
    textColor: '#000000',
  },
  {
    cellColor: '#0073e6',
    textColor: '#ffffff',
  },
  {
    cellColor: '#054fb9',
    textColor: '#ffffff',
  },
];

export const DEFAULT_HEADER_Z_INDEX = 1000;
export const DEFAULT_Z_INDEX = 10001;
export const DEFAULT_TABLE_WIDTH = 550;
export const DEFAULT_TABLE_SIZE = 500;
export const DEFAULT_TABLE_HEIGHT = 87;
export const DEFAULT_TABLE_HEIGHT_OFFSET = 50;
export const DEFAULT_TABLE_HEIGHT_VH = '80vh';

// Offset for table width to adapt to container padding
export const DEFAULT_TABLE_WIDTH_OFFSET = 10;
export const DEFAULT_MAX_WIDTH_TABLE_SIZE = DEFAULT_TABLE_WIDTH + DEFAULT_TABLE_WIDTH_OFFSET;

// Medium table width
export const DEFAULT_TABLE_WIDTH_MD = 910;

// Sorting titles
export const DEFAULT_SORTING_TITLE = 'Clear sort';
export const DEFAULT_ASC_SORTING_TITLE = 'Sort ascending';
export const DEFAULT_DESC_SORTING_TITLE = 'Sort descending';

export const DEFAULT_ADD_ALL_TO_VIEW = 'Add All to View';
export const DEFAULT_REMOVE_ALL_FROM_VIEW = 'Remove All from View';

export const DEFAULT_SELECT_ALL = 'Select All';
export const DEFAULT_ADD_ALL = 'Add All';
export const DEFAULT_REMOVE_ALL = 'Remove All';
export const DEFAULT_CLEAR_SELECTION = 'Clear Selection';
export const DEFAULT_ADD_SELECTED = 'Add Selected';
export const DEFAULT_REMOVE_SELECTED = 'Remove Selected';

export const DEFAULT_CREATED_BY = 'ViewsManager';
export const VIEW_DELETED_SUCCESS_MESSAGE = 'View deleted successfully.';
export const VIEW_DELETED_ERROR_MESSAGE = 'View could not be deleted.';
// Ordinals
export const ORDINAL_ZERO = 0;
export const ORDINAL_ONE = 1;

// Numerical values
export const NUMERICAL_ZERO = 0;
export const NUMERICAL_MINUS_ONE = -1;
export const NUMERICAL_ONE = 1;

export const DEFAULT_VIEW: View = {
  accessibleBy: [],
  createdBy: DEFAULT_CREATED_BY,
  filter: {
    auto_add_assignments: false,
    auto_add_personnel: false,
    filter_id: DEFAULT_INVALID_INTEGER,
    on_assignments: [],
    on_demandTallies: [],
    on_departments: [],
    on_personnel: [],
    on_tallies: [],
    on_templates: [],
    sort_assignments_by: ViewEditorDraftSortByAssignmentOptions.CUSTOM,
    sort_personnel_by: ViewEditorDraftSortByPersonnelOptions.CUSTOM,
  },
  filterId: DEFAULT_INVALID_INTEGER,
  name: '',
  personnel: [],
  publicViewId: null,
  templates: [],
  theme: {
    data: {
      GridSettings_leftColumnType: GridSettingsLeftColumnType.ASSIGNMENT,
      blockAnchorDate: DateTime.now().toISODate(),
      blockLength: '1',
      blockStart: DaysOfWeekAbbreviationFromFullString.Monday,
      blockStaticStart: DateTime.now().toISODate(),
      blockStaticStop: DateTime.now().plus({ week: 1 }).toISODate(),
      blockViewTotalWeeks: 1,
      blockWeekLength: '1',
      colorMethod: 'mixed',
      colorTextMethod: 'colored',
      compactMode: BooleanYesNo.NO,
      condenseColumns: BooleanYesNo.NO,
      dataType: ViewLayoutDataType.BOTH,
      displayDepartmentNames: ViewEditorDisplayDepartmentNames.NONE,
      hideBlankRows: BooleanYesNo.YES,
      hidePending: BooleanYesNo.NO,
      hideWeekends: BooleanYesNo.NO,
      layout: ViewLayoutType.STANDARD,
      listColumns: [],
      multiSortColumns: [],
      personalFilter: BooleanYesNo.NO,
      range: ViewLayoutDisplayRange.MONTH,
      shouldCountTalliesByPersonnelFilter: BooleanYesNo.NO,
      showLoggedInOnly: BooleanYesNo.NO,
      showTimes: BooleanYesNo.NO,
      startOnDay: 'Mon',
    },
    name: '',
    theme_id: DEFAULT_INVALID_INTEGER,
  },
  themeId: DEFAULT_INVALID_INTEGER,
  updatedBy: '',
  updatedTimestamp: '',
  viewId: DEFAULT_INVALID_INTEGER,
};

export const DEFAULT_PERSONNEL_VIEW_ACCESS: PersonnelViewAccessBody = {
  filterIds: [],
  id: DEFAULT_PERSONNEL_ID,
  viewIds: [],
};

export const DEFAULT_PERSONNEL_VIEW_MEMBERSHIP: PersonnelViewMembership = {
  accessibleViews: [],
  id: DEFAULT_PERSONNEL_ID,
  is_staff: false,
  memberOfFilters: [],
  memberOfViews: [],
  metadata: {
    link: '',
  },
  name: {
    display: '',
    first: '',
    last: '',
  },
  views: 0,
};

export const DEFAULT_COLOR_ITEM: ViewColoredItem = {
  color: null,
  colorText: null,
  id: DEFAULT_INVALID_INTEGER,
};

export const DEFAULT_PERSONNEL_SORT_ORDER = PersonnelSortOrderDropDownOptions.find(
  (option) => option.value === ViewEditorDraftSortByPersonnelOptions.DISPLAY_NAME,
);

export const DEFAULT_GAP_VALUE = 2;
export const DEFAULT_ZERO = 0;
