import { Row } from '@tanstack/react-table';

import Comparisons from '@/utils/comparisons';

export const tableColumnFilterFn = <T>(row: Row<T>, columnId: string, filterValue: string) => {
  if (!filterValue) return true;

  const columnName = row.getValue(columnId) as string;

  return Comparisons.advancedFilter(columnName, filterValue);
};
