/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from 'lodash';
import React from 'react';

import { useGetAssignmentTypesQuery } from '@/API/assignments.api';
import { useGetPersonnelTypesQuery } from '@/API/personnel.api';
import {
  useCreateFilterMutation,
  useCreateThemeMutation,
  useCreateViewMutation,
  useUpdateFilterMutation,
  useUpdateThemeMutation,
  useUpdateViewMutation,
} from '@/API/views.api';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog';
import StickyDrawerFooter from '@/components/drawer-footer/StickyDrawerFooter';
import ViewEditorAssignmentsTallies from '@/components/view-editor/ViewEditorAssignmentsTallies';
import ViewEditorBasicProperties from '@/components/view-editor/ViewEditorBasicProperties';
import ViewEditorPersonnel from '@/components/view-editor/ViewEditorPersonnel';
import ViewEditorViewerConfiguration from '@/components/view-editor/ViewEditorViewerConfiguration';
import UIConfig from '@/config/ui.config';
import { FEATURE_FLAGS_ENUM } from '@/constants/config';
import {
  DEFAULT_CREATE_SUCCESS_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_UPDATE_SUCCESS_MESSAGE,
  ToastTypes,
} from '@/constants/defaults';
import { ViewEditorDrawerMode, ViewEditorDrawerTabIndices } from '@/constants/enums';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewEditorAdvancedSortingRulesIsEnabled,
  setViewEditorDraftAssignmentTypes,
  setViewEditorDraftDepartmentIds,
  setViewEditorDraftMultiSortListLayout,
  setViewEditorDraftNavIsBlocked,
  setViewEditorDraftPersonnelTypes,
  setViewEditorDraftTemplateIds,
  setViewEditorShowConfirmRemoveDepartment,
  setViewEditorShowConfirmSaveChanges,
  setViewEditorTabIndex,
} from '@/store/slices/viewEditor.slice';
import { RootState } from '@/store/store';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import { ApiError } from '@/types/api.types';
import { DropdownAction } from '@/types/ui.types';
import { ViewLayoutType } from '@/types/view.types';

interface ViewEditorDrawerProps {
  onClose: () => void;
  onSave: (viewId: number) => void;
}

const ViewEditorDrawer = (props: ViewEditorDrawerProps): React.JSX.Element => {
  const { onClose, onSave } = props;

  const { data: assignmentTypes, isSuccess: getAssignmentTypesSuccess } = useGetAssignmentTypesQuery();
  const { data: personnelTypes, isSuccess: getPersonnelTypesSuccess } = useGetPersonnelTypesQuery();

  const [updateFilter, updateFilterResponse] = useUpdateFilterMutation();
  const [updateTheme, updateThemeResponse] = useUpdateThemeMutation();
  const [updateView, updateViewResponse] = useUpdateViewMutation();

  const [createFilter, createFilterResponse] = useCreateFilterMutation();
  const [createTheme, createThemeResponse] = useCreateThemeMutation();
  const [createView, createViewResponse] = useCreateViewMutation();

  const { isLoading, isOpen, mode, navIsBlocked, showConfirmRemoveDepartment, showConfirmSaveChanges, tabIndex } =
    useAppSelector((state: RootState) => state.viewEditor.uiState);

  const {
    uiState: {
      assignmentTypes: assignmentTypesOptions,
      personnelTypes: personnelTypesOptions,
      selectedDepartments,
      selectedTemplates,
    },
    viewDraft,
    viewOriginal,
  } = useAppSelector((state: RootState) => state.viewEditor);

  const dispatch = useAppDispatch();

  const flags = useFlags();
  const toast = useToast();

  // Local copy of the selected departments to manage the confirmation modal when removing a department
  const [selectedDepartmentIds, setSelectedDepartmentIds] = React.useState<number[]>([]);

  React.useEffect(() => {
    const showAdvancedSortingRulesFlag = flags[FEATURE_FLAGS_ENUM.LIST_VIEW_SORTING];
    dispatch(setViewEditorAdvancedSortingRulesIsEnabled(showAdvancedSortingRulesFlag));

    const isListLayout = viewDraft.theme.data.layout === ViewLayoutType.LIST;
    
    if (showAdvancedSortingRulesFlag) {
      dispatch(setViewEditorDraftMultiSortListLayout(isListLayout));
    }
  },[dispatch, flags, viewDraft.theme.data.layout])

  React.useEffect(() => {
    if (getPersonnelTypesSuccess && !personnelTypesOptions.length)
      dispatch(setViewEditorDraftPersonnelTypes(personnelTypes));
  }, [dispatch, getPersonnelTypesSuccess, personnelTypes, personnelTypesOptions.length]);

  React.useEffect(() => {
    if (getAssignmentTypesSuccess && !assignmentTypesOptions.length)
      dispatch(setViewEditorDraftAssignmentTypes(assignmentTypes));
  }, [assignmentTypes, assignmentTypesOptions.length, dispatch, getAssignmentTypesSuccess]);

  React.useEffect(() => {
    const allRequiredFieldsSatisfied = [
      selectedDepartments.length,
      selectedTemplates.length,
      viewDraft.name.length >= UIConfig.MINIMUM_VIEW_NAME_LENGTH,
      viewDraft.name.length <= UIConfig.MAXIMUM_VIEW_NAME_LENGTH,
    ].every(Boolean);
    if (!allRequiredFieldsSatisfied) {
      dispatch(setViewEditorDraftNavIsBlocked(true));
    } else {
      dispatch(setViewEditorDraftNavIsBlocked(false));
    }
  }, [dispatch, selectedDepartments.length, selectedTemplates.length, viewDraft.name.length]);

  const isDirty = () => {
    return !_.isEqual(viewOriginal, viewDraft);
  };

  const handleClose = (bypassConfirm = false) => {
    if (isDirty() && !bypassConfirm) {
      dispatch(setViewEditorShowConfirmSaveChanges(true));
    } else {
      onClose();
    }
  };

  const handleCloseWithoutSaving = () => {
    dispatch(setViewEditorShowConfirmSaveChanges(false));
    onClose();
  };

  const addSuccessToast = (message: string) => {
    toast({
      duration: DEFAULT_TOAST_DURATION,
      isClosable: true,
      position: 'top',
      status: ToastTypes.SUCCESS,
      title: message,
    });
  };

  const addErrorToast = (message?: string) => {
    toast({
      duration: DEFAULT_TOAST_DURATION,
      isClosable: true,
      position: 'top',
      status: ToastTypes.ERROR,
      title: message || DEFAULT_ERROR_MESSAGE,
    });
  };

  const closeConfirmSaveChangesDialog = () => {
    dispatch(setViewEditorShowConfirmSaveChanges(false));
  };

  // Save should be disabled in Creation mode unless you are in the final tab
  const notLastCreationStep =
    mode === ViewEditorDrawerMode.CREATE && tabIndex !== ViewEditorDrawerTabIndices.VIEWER_CONFIGURATION;

  const handleSave = async () => {
    if (!isDirty) handleClose(true);

    // if opened, close the Confirm dialog
    if (showConfirmSaveChanges) {
      closeConfirmSaveChangesDialog();
    }

    if (mode === ViewEditorDrawerMode.CREATE && !navIsBlocked) {
      // Create new view
      try {
        if (!viewDraft?.filter || !viewDraft?.theme) return;
        const filterResponse = await createFilter({
          filterData: viewDraft.filter,
          filterName: `${viewDraft.name} Filter`,
        }).unwrap();

        const themeResponse = await createTheme({
          themeData: viewDraft.theme.data,
          themeName: `${viewDraft.name} Theme`,
        }).unwrap();

        if (filterResponse.filter_id && themeResponse.theme_id) {
          const viewResponse = await createView({
            ...viewDraft,
            filterId: filterResponse.filter_id,
            themeId: themeResponse.theme_id,
          }).unwrap();

          const createViewHasError = !filterResponse.filter_id || !themeResponse.theme_id || !viewResponse.view_id;

          if (createViewHasError) {
            addErrorToast();
          } else {
            addSuccessToast(DEFAULT_CREATE_SUCCESS_MESSAGE);
          }
          if (viewResponse.view_id) {
            onSave(viewResponse.view_id);
          }
        }
      } catch (error) {
        // Cast unknown error type to ApiError
        const apiError = error as ApiError;
        const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;
        addErrorToast(message);
      }
    } else {
      // Edit existing view
      // If no view, bail
      if (!viewDraft) return;

      try {
        await updateFilter(viewDraft.filter).unwrap();

        const { data, name, theme_id } = viewDraft.theme;

        await updateTheme({
          data,
          name,
          // eslint-disable-next-line camelcase
          theme_id,
        }).unwrap();

        await updateView({
          accessibleBy: viewDraft.accessibleBy,
          filterId: viewDraft.filterId,
          name: viewDraft.name,
          themeId: viewDraft.themeId,
          viewId: viewDraft.viewId,
        }).unwrap();

        addSuccessToast(DEFAULT_UPDATE_SUCCESS_MESSAGE);

        if (viewDraft.viewId) {
          onSave(viewDraft.viewId);
        }
      } catch (error) {
        // Cast unknown error type to ApiError
        const apiError = error as ApiError;
        const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;
        addErrorToast(message);
      }
    }
  };

  if (!viewDraft) return <></>;

  const handleDepartmentChange = (departments: number[], action?: DropdownAction) => {
    // If the user is removing items (deselecting) or clearing (removing all items from the dropdown
    // show the confirmation dialog and save locally the new selected items
    // Otherwise, the user is selecting departments, and we just continue with the selection
    if (action === DropdownAction.DESELECT_OPTION || action === DropdownAction.CLEAR) {
      // Set the selected departments on the local copy
      setSelectedDepartmentIds(departments);
      dispatch(setViewEditorShowConfirmRemoveDepartment(true));
    } else {
      dispatch(setViewEditorDraftDepartmentIds(departments));
    }
  };

  // Close the modal and remove selected departments
  const handleCancelDepartmentRemoval = () => {
    setSelectedDepartmentIds([]);
    dispatch(setViewEditorShowConfirmRemoveDepartment(false));
  };

  // Set selected departments based on the list of items selected on the dropdown
  const handleConfirmDepartmentRemoval = () => {
    dispatch(setViewEditorDraftDepartmentIds(selectedDepartmentIds));
    if (!selectedDepartmentIds.length) {
      dispatch(setViewEditorDraftTemplateIds([]));
    }
    handleCancelDepartmentRemoval();
  };

  // Disable save button if form has errors or the user has not navigated to the last step
  const isSaveDisabled = navIsBlocked || notLastCreationStep;

  const getSaveConfirmationDialog = (): React.JSX.Element => {
    if (isSaveDisabled && mode === ViewEditorDrawerMode.CREATE) {
      return (
        <ConfirmationDialog
          closeButtonLabel="Close"
          closeConfirmDialog={() => dispatch(setViewEditorShowConfirmSaveChanges(false))}
          confirmButtonLabel="Continue Create"
          description="Are you sure you want to close without creating the view?"
          handleClose={handleCloseWithoutSaving}
          handleConfirm={closeConfirmSaveChangesDialog}
          isOpen={showConfirmSaveChanges}
          title="Confirm Close"
        />
      );
    }

    return (
      <ConfirmationDialog
        closeButtonLabel="Close"
        closeConfirmDialog={() => dispatch(setViewEditorShowConfirmSaveChanges(false))}
        confirmButtonLabel="Save"
        description="Are you sure you want to close without saving?"
        handleClose={handleCloseWithoutSaving}
        handleConfirm={handleSave}
        isOpen={showConfirmSaveChanges}
        title="Confirm Close"
      />
    );
  };

  return (
    <Drawer
      blockScrollOnMount={true}
      isOpen={isOpen}
      onClose={handleClose}
      placement={'right'}
      preserveScrollBarGap={false}
      returnFocusOnClose={false}
    >
      {getSaveConfirmationDialog()}

      <ConfirmationDialog
        closeButtonLabel="Cancel"
        closeConfirmDialog={() => dispatch(setViewEditorShowConfirmRemoveDepartment(false))}
        confirmButtonLabel="Confirm"
        description="Changing your selected departments may affect your Available Personnel and Assignments. Are you sure you want to make this change?"
        handleClose={handleCancelDepartmentRemoval}
        handleConfirm={handleConfirmDepartmentRemoval}
        isOpen={showConfirmRemoveDepartment}
        title="Warning"
      />

      <DrawerOverlay />
      <DrawerContent w={'1300px'} maxW={'1300px'}>
        <DrawerCloseButton />
        {mode === ViewEditorDrawerMode.EDIT ? (
          <DrawerHeader data-testid={`${ELEMENT_DATA_TEST_IDS.VIEW_EDITOR_DRAWER_HEADER}-${viewDraft.viewId}`}>
            Editing View - {viewDraft.name}
          </DrawerHeader>
        ) : (
          <DrawerHeader data-testid={ELEMENT_DATA_TEST_IDS.CREATE_VIEW_EDITOR_DRAWER_HEADER}>Creating New View</DrawerHeader>
        )}
        <DrawerBody overflowX={'hidden'} pt={0}>
          {isLoading ? (
            <Box textAlign={'center'}>
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Box>
          ) : (
            <Tabs isLazy index={tabIndex} onChange={(index) => dispatch(setViewEditorTabIndex(index))}>
              <HStack gap={10} position={'sticky'} top={0} zIndex={99999} bg={'#fff'}>
                <TabList>
                  <Tab>View Properties</Tab>
                  <Tab isDisabled={navIsBlocked}>Personnel</Tab>
                  <Tab isDisabled={navIsBlocked}>Assignments & Tallies</Tab>
                  <Tab isDisabled={navIsBlocked}>Viewer Configuration</Tab>
                </TabList>
              </HStack>
              <TabPanels>
                <TabPanel>
                  <ViewEditorBasicProperties
                    handleDepartmentOptionsChange={handleDepartmentChange}
                    handleTemplateOptionsChange={(selectedTemplateIds) =>
                      dispatch(setViewEditorDraftTemplateIds(selectedTemplateIds))
                    }
                  />
                </TabPanel>
                <TabPanel>
                  <ViewEditorPersonnel />
                </TabPanel>
                <TabPanel>
                  <ViewEditorAssignmentsTallies />
                </TabPanel>
                <TabPanel>
                  <ViewEditorViewerConfiguration />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </DrawerBody>
        <StickyDrawerFooter
          isSaveDisabled={isSaveDisabled}
          navigationIsBlocked={navIsBlocked}
          onCancel={handleClose}
          onSave={handleSave}
          showNavigation={true}
          tabIndex={tabIndex}
        />
      </DrawerContent>
    </Drawer>
  );
};

export default ViewEditorDrawer;
