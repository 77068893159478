import APIConstants from '@/constants/api';

const isLBSAccount = (id: number) => APIConstants.LBS_USER_IDS.includes(id);

const advancedFilter = (textToSearch: string, searchValue: string) => {
  if (!searchValue) return true;
  const valueToSearch = textToSearch.toLowerCase();
  const searchTerm = searchValue.toLowerCase();

  return searchTerm
    .split(/\s+/)
    .filter(Boolean)
    .every((word) => valueToSearch.includes(word));
};

const Comparisons = {
  advancedFilter,
  isLBSAccount,
};

export default Comparisons;
