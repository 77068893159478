import { UniqueId } from '@/types/ui.types';
import { ViewLayoutType } from '@/types/view.types';

export const isGroupByAssignmentsLayout = (layout: ViewLayoutType): boolean => {
  const validLayouts = [ViewLayoutType.STANDARD, ViewLayoutType.COLUMNS, ViewLayoutType.CALENDAR, ViewLayoutType.GANTT];

  return validLayouts.includes(layout);
};

export const isDemandTallyId = (id: string): boolean => {
  return isNaN(Number.parseInt(id));
};

export const getId = (key: string | UniqueId): string | number => {
  let id: string | number = key;

  if (!Number.isNaN(Number(key))) {
    id = Number(key);
  }
  return id;
};
