import {
  AccessorFnColumnDef,
  CellContext,
  ColumnFilter,
  ColumnFiltersState,
  Table,
  VisibilityState,
} from '@tanstack/react-table';
import { Draft, WritableDraft } from 'immer';
import _ from 'lodash';
import React from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { createUseStyles } from 'react-jss';
import { useImmer } from 'use-immer';

import { cellFactory } from '@/components/available-preview-list/aplUtils';
import PaginatedPreviewTable from '@/components/paginated-preview-table/PaginatedPreviewTable';
import PreviewTableHeader from '@/components/preview-table-header/PreviewTableHeader';
import TableHeader from '@/components/table-header/TableHeader';
import TableHeaderSelectAll from '@/components/table-header-select-all/TableHeaderSelectAll';
import { DEFAULT_STRING_ARRAY_COMPARE_ASCENDING, DEFAULT_STRING_ARRAY_COMPARE_DESCENDING } from '@/constants/defaults';
import { TableOrderDesc, ViewEditorDrawerMode } from '@/constants/enums';
import { useAppSelector } from '@/store/hooks';
import { APLItem, CellColor, HeaderSortHandlers, UniqueId } from '@/types/ui.types';
import SortingUtils from '@/utils/sorting';
import { getId } from '@/utils/validators';

enum ColumnFilterStateProperty {
  AvailableItemsColumnFilters = 'availableItemsColumnFilters',
  PreviewItemsColumnFilters = 'previewItemsColumnFilters',
}

type TableColumnIds = { [key: string]: string };

type ItemMap<T> = Map<UniqueId, APLItem<T>>;

/*
 * To prevent multiple state updates while keeping relevant filters centralized to a single location,
 * we need to differentiate between internal and external filters to prevent overwriting or omitting filters which
 * should be handled internally by the component.
 *
 * Internal filters are not affected by filters passed in via props, IFF the internal filters are marked as such. (This is up to you to specify)
 *
 * This should really only apply to "available" items (left-hand side), as the preview items are simply a result of user selection and
 * are generally unaffected by external filters. However, for the sake of consistency, we will type both available and preview item filter states, as CustomColumnFilterState.
 * */
interface CustomColumnFilter extends ColumnFilter {
  internal?: boolean;
}

type CustomColumnFiltersState = CustomColumnFilter[];

interface ITableState<T> {
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  availableItems: ItemMap<T>;
  availableItemsColumnFilters: CustomColumnFiltersState;
  isLoading: boolean;
  previewColumns: AccessorFnColumnDef<APLItem<T>>[];
  previewItems: ItemMap<T>;
  previewItemsColumnFilters: CustomColumnFiltersState;
  selectedAvailableRowIds: Set<UniqueId>;
  selectedPreviewRowIds: Set<UniqueId>;
  tableOrderDesc: TableOrderDesc;
  unfilteredViewIds: number[];
}

interface IAvailablePreviewListProps<T> {
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly availableColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  readonly availableItems: ItemMap<T>;
  readonly availableItemsPrimaryColumnId: string;
  readonly availableItemsTableLabel: string;
  readonly availableRowUniqueKey: string;
  // readonly currentlySelectedItemIds: number[];
  readonly isOpen: boolean;
  readonly onItemsChanged: (updatedData: T[]) => void;
  // React Table column def appeasement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly previewColumns: AccessorFnColumnDef<APLItem<T>, any>[];
  readonly previewItems: ItemMap<T>;
  readonly previewItemsPrimaryColumnId: string;
  readonly previewItemsTableLabel: string;
  readonly previewRowUniqueKey: string;
  readonly primaryAvailableColumnIndex: number;
  readonly primaryPreviewColumnIndex: number;
  readonly tableColumnIds: TableColumnIds;
  readonly availableItemsColumnFilters?: ColumnFiltersState;
  readonly availableColumnsVisibility?: VisibilityState;
  readonly availableItemsTableNoDataFoundMessage?: string;
  readonly cellContentInjector?: (item: CellContext<APLItem<T>, unknown>) => React.JSX.Element;
  readonly deselectAllTextOverride?: string;
  readonly injectedContentOverridesText?: boolean;
  readonly isLoading?: boolean;
  readonly onSortingStateChanged?: (sortingState: TableOrderDesc) => void;
  readonly overrideSorting?: boolean;
  readonly previewColumnsVisibility?: VisibilityState;
  readonly previewHeaderSortHandlers?: HeaderSortHandlers;
  readonly previewRowsAreDraggable?: boolean;
  readonly selectAllTextOverride?: string;
  // readonly previewTableDragHandler?: (updatedData: unknown[]) => void;
  readonly showColorPicker?: boolean;
  readonly showSideControls?: boolean;
  readonly topPosition?: string;
}

const useSharedRowStyles = createUseStyles({
  row: {
    '&:hover': {
      backgroundColor: '#E2E8F0',
      color: 'white',
    },
    cursor: 'pointer',
  },
});

const usePreviewRowStyle = createUseStyles({
  icon: {
    '&:hover': {
      color: '#d21515',
    },
    color: '#5b5757',
    cursor: 'pointer',
  },
});

const clearColumnFilter = <T,>(
  columnName: keyof TableColumnIds,
  filterStateDraft: WritableDraft<ITableState<T>>,
  columnFilterProperty: ColumnFilterStateProperty,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filterStateDraft[columnFilterProperty.valueOf()] = filterStateDraft[columnFilterProperty.valueOf()].filter(
    (filterValue: { id: string | number }) => filterValue.id !== columnName,
  );
};

const updateColumnFilter = <T,>(
  columnName: keyof TableColumnIds,
  filterStateDraft: WritableDraft<ITableState<T>>,
  columnFilterProperty: ColumnFilterStateProperty,
  filterValue: string | number[],
  isInternal = false,
) => {
  clearColumnFilter(columnName, filterStateDraft, columnFilterProperty);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  filterStateDraft[columnFilterProperty.valueOf()].push({
    id: columnName,
    internal: isInternal,
    value: filterValue,
  });
};

const AvailablePreviewList = <T,>(props: IAvailablePreviewListProps<T>): React.JSX.Element => {
  const {
    availableColumns,
    availableColumnsVisibility,
    availableItems,
    availableItemsColumnFilters = [],
    availableItemsPrimaryColumnId,
    availableItemsTableLabel,
    availableItemsTableNoDataFoundMessage,
    availableRowUniqueKey,
    cellContentInjector,
    injectedContentOverridesText = false,
    isLoading = false,
    isOpen,
    onItemsChanged,
    overrideSorting = false,
    previewColumns,
    previewColumnsVisibility,
    previewItems,
    previewItemsPrimaryColumnId,
    previewItemsTableLabel,
    previewRowUniqueKey,
    primaryAvailableColumnIndex,
    primaryPreviewColumnIndex,
    previewRowsAreDraggable = false,
    showColorPicker = false,
    showSideControls = false,
    tableColumnIds,
    topPosition,
  } = props;

  const [tableState, updateTableState] = useImmer<ITableState<T>>({
    availableColumns: [],
    availableItems: availableItems ?? new Map(),
    availableItemsColumnFilters,
    isLoading: false,
    previewColumns: [],
    previewItems: previewItems ?? new Map(),
    previewItemsColumnFilters: [],
    selectedAvailableRowIds: new Set<number | string>(),
    selectedPreviewRowIds: new Set<number | string>(),
    tableOrderDesc: { availableItemsDesc: false, previewItemsDesc: false },
    unfilteredViewIds: [],
  });

  const {
    uiState: { mode },
  } = useAppSelector((state) => state.viewEditor);

  const isViewCreation = React.useMemo(() => mode === ViewEditorDrawerMode.CREATE, [mode]);

  const prevPreviewItemsDesc = React.useRef(tableState.tableOrderDesc.previewItemsDesc);

  // These refs are used to store the table instances for the available and preview items tables
  // React Table is handling the filtering, so we can just sneakily grab the filtered rows from the table instance
  // instead of managing the filtered rows ourselves
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const availableItemsTableRef = React.useRef<Table<any> | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const previewItemsTableRef = React.useRef<Table<any> | undefined>();

  const prevPreviewItems = React.useRef(JSON.stringify([...previewItems.values()]));
  const prevAvailableItemKeys = React.useRef([...availableItems.keys()]);

  const prevAvailableItemsColumnFilters = React.useRef(availableItemsColumnFilters);

  const rowClasses = useSharedRowStyles();
  const previewClasses = usePreviewRowStyle();

  const sortPreviewItems = React.useCallback(
    (a: string, b: string) => {
      return (
        SortingUtils.sortStrings(a, b) *
        (tableState.tableOrderDesc.previewItemsDesc
          ? DEFAULT_STRING_ARRAY_COMPARE_ASCENDING
          : DEFAULT_STRING_ARRAY_COMPARE_DESCENDING)
      );
    },
    [tableState.tableOrderDesc.previewItemsDesc],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDisplayName = (row: any) => {
    const displayName = row.original.personnelName?.display || row.original.displayName || row.original.name;
    return displayName.toLowerCase();
  };

  /* --- Available Table Header handlers [START] --- */
  const handleAddAllAvailableItems = React.useCallback(() => {
    // If there are available items
    if (tableState.availableItems.size) {
      // If we have filters, only move items that match the filters
      if (tableState.availableItemsColumnFilters.length && availableItemsTableRef?.current) {
        let matchingRows = availableItemsTableRef.current.getFilteredRowModel().rows;
        // if in view creation mode, sort the available items
        // the reason for this is to initially have sorted preview Items table
        // even with manualSorting set as true for DnD to work
        if (isViewCreation) {
          matchingRows = matchingRows.sort((a, b) => {
            const nameA = getDisplayName(a);
            const nameB = getDisplayName(b);
            return sortPreviewItems(nameA, nameB);
          });
        }
        updateTableState((draft) => {
          for (const key of Object.keys(matchingRows)) {
            const id = getId(key);
            const item = { ...tableState.availableItems.get(id) };

            if (!item) return;

            draft.previewItems.set(id, item as Draft<APLItem<T>>);
            draft.availableItems.delete(id);
          }
        });
      } else {
        // If we don't have filters, move all available items to preview items
        updateTableState((draft) => {
          let previewItemsArray = [...draft.availableItems.entries()];

          // if we are in view creation mode, sort the items by name
          if (isViewCreation) {
            previewItemsArray = previewItemsArray.sort(([, a], [, b]) => {
              const rowA = { original: a };
              const rowB = { original: b };
              return sortPreviewItems(getDisplayName(rowA), getDisplayName(rowB));
            });
          }

          for (const [k, v] of previewItemsArray) {
            const id = getId(k);

            draft.previewItems.set(id, v);
          }

          draft.availableItems.clear();
        });
      }
    }
  }, [
    isViewCreation,
    sortPreviewItems,
    tableState.availableItems,
    tableState.availableItemsColumnFilters.length,
    updateTableState,
  ]);

  const handleAddSelectedAvailableItems = React.useCallback(() => {
    // If there are available items
    if (tableState.availableItems.size) {
      if (availableItemsTableRef?.current) {
        let matchingRows = availableItemsTableRef.current
          .getFilteredRowModel()
          .rows.filter((item) => tableState.selectedAvailableRowIds.has(item.id));

        if (isViewCreation) {
          matchingRows = matchingRows.sort((a, b) => {
            const nameA = getDisplayName(a);
            const nameB = getDisplayName(b);
            return sortPreviewItems(nameA, nameB);
          });
        }
        updateTableState((draft) => {
          matchingRows.forEach((element) => {
            const id = getId(element.id);

            const item = { ...tableState.availableItems.get(id) };

            if (!item) return;

            draft.previewItems.set(id, item as Draft<APLItem<T>>);
            draft.selectedAvailableRowIds.delete(id);
            draft.availableItems.delete(id);
          });
        });
      }
    }
  }, [
    isViewCreation,
    sortPreviewItems,
    tableState.availableItems,
    tableState.selectedAvailableRowIds,
    updateTableState,
  ]);

  const handleSelectAllAvailableItems = React.useCallback(() => {
    // Handle select all available items
    if (tableState.availableItemsColumnFilters.length && availableItemsTableRef?.current) {
      const matchingRows = availableItemsTableRef.current.getFilteredRowModel()?.rowsById;

      updateTableState((draft) => {
        for (const key of Object.keys(matchingRows)) {
          const id = getId(key);

          draft.selectedAvailableRowIds.add(id);
        }
      });
    } else {
      // If we do not have any filters, select all available items
      updateTableState((draft) => {
        for (const key of draft.availableItems.keys()) {
          const id = getId(key);

          draft.selectedAvailableRowIds.add(id);
        }
      });
    }
  }, [tableState.availableItemsColumnFilters.length, updateTableState]);

  const handleClearSelectedAvailableItems = React.useCallback(() => {
    if (tableState.availableItemsColumnFilters.length && availableItemsTableRef?.current) {
      const matchingRows = availableItemsTableRef.current.getFilteredRowModel()?.rowsById;

      updateTableState((draft) => {
        for (const key of Object.keys(matchingRows)) {
          const id = getId(key);

          draft.selectedAvailableRowIds.delete(id);
        }
      });
    } else {
      updateTableState((draft) => {
        draft.selectedAvailableRowIds.clear();
      });
    }
  }, [tableState.availableItemsColumnFilters.length, updateTableState]);

  const handleAvailableItemClick = React.useCallback(
    (uniqueId: UniqueId) => {
      const id = getId(uniqueId);

      updateTableState((draft) => {
        const updatedSelectedAvailableItemIdsRowIds = new Set(draft.selectedAvailableRowIds);

        if (updatedSelectedAvailableItemIdsRowIds.has(id)) {
          updatedSelectedAvailableItemIdsRowIds.delete(id);
        } else {
          updatedSelectedAvailableItemIdsRowIds.add(id);
        }

        draft.selectedAvailableRowIds = updatedSelectedAvailableItemIdsRowIds;
      });
    },
    [updateTableState],
  );

  /* --- Available Table Header handlers [END] --- */

  /* --- Preview Table Header handlers [START] --- */
  const handleRemoveAllPreviewItems = React.useCallback(() => {
    // If there are available items
    if (tableState.previewItems.size) {
      // If we have filters, only move items that match the filters
      if (tableState.previewItemsColumnFilters.length && previewItemsTableRef?.current) {
        const matchingRows = previewItemsTableRef.current.getFilteredRowModel()?.rowsById;

        updateTableState((draft) => {
          for (const key of Object.keys(matchingRows)) {
            const id = getId(key);

            const item = { ...tableState.previewItems.get(id) };

            if (!item) return;

            draft.availableItems.set(id, item as Draft<APLItem<T>>);
            draft.previewItems.delete(id);
          }
        });
      } else {
        // If we don't have filters, move all available items to preview items
        updateTableState((draft) => {
          for (const [k, v] of draft.previewItems.entries()) {
            draft.availableItems.set(k, v);
          }

          draft.previewItems.clear();
        });
      }
    }
  }, [tableState.previewItems, tableState.previewItemsColumnFilters.length, updateTableState]);

  const handleSelectAllPreviewItems = React.useCallback(() => {
    // Handle select all available items
    if (tableState.previewItemsColumnFilters.length && previewItemsTableRef?.current) {
      const matchingRows = previewItemsTableRef.current.getFilteredRowModel()?.rowsById;

      updateTableState((draft) => {
        for (const key of Object.keys(matchingRows)) {
          const id = getId(key);

          draft.selectedPreviewRowIds.add(id);
        }
      });
    } else {
      // If we do not have any filters, select all available items
      updateTableState((draft) => {
        for (const key of draft.previewItems.keys()) {
          const id = getId(key);

          draft.selectedPreviewRowIds.add(id);
        }
      });
    }
  }, [tableState.previewItemsColumnFilters.length, updateTableState]);

  const handleClearSelectedPreviewItems = React.useCallback(() => {
    if (tableState.previewItemsColumnFilters.length && previewItemsTableRef?.current) {
      const matchingRows = previewItemsTableRef.current.getFilteredRowModel()?.rowsById;

      updateTableState((draft) => {
        for (const key of Object.keys(matchingRows)) {
          const id = getId(key);

          draft.selectedPreviewRowIds.delete(id);
        }
      });
    }
    updateTableState((draft) => {
      draft.selectedPreviewRowIds.clear();
    });
  }, [tableState.previewItemsColumnFilters.length, updateTableState]);

  const handleRemoveSelectedPreviewItems = React.useCallback(() => {
    // If there are preview items
    if (tableState.previewItems.size) {
      if (previewItemsTableRef?.current) {
        const matchingRows = previewItemsTableRef.current
          .getFilteredRowModel()
          .rows.filter((item) => tableState.selectedPreviewRowIds.has(item.id));

        updateTableState((draft) => {
          matchingRows.forEach((element) => {
            const id = getId(element.id);

            const item = { ...tableState.previewItems.get(id) };

            if (!item) return;

            draft.availableItems.set(id, item as Draft<APLItem<T>>);
            draft.selectedPreviewRowIds.delete(id);
            draft.previewItems.delete(id);
          });
        });
      }
    }
  }, [tableState.previewItems, tableState.selectedPreviewRowIds, updateTableState]);

  const handlePreviewRowClick = React.useCallback(
    (uniqueId: UniqueId) => {
      updateTableState((draft) => {
        const id = getId(uniqueId);

        const updatedSelectedPreviewRowIds = new Set(draft.selectedPreviewRowIds);

        if (updatedSelectedPreviewRowIds.has(id)) {
          updatedSelectedPreviewRowIds.delete(id);
        } else {
          updatedSelectedPreviewRowIds.add(id);
        }

        draft.selectedPreviewRowIds = updatedSelectedPreviewRowIds;
      });
    },
    [updateTableState],
  );

  const handlePreviewItemClick = React.useCallback(
    (uniqueId: UniqueId) => {
      const id = getId(uniqueId);

      updateTableState((draft) => {
        const item = draft.previewItems.get(id);

        if (!item) return;

        item.color = null;
        item.colorText = null;

        draft.selectedPreviewRowIds.delete(id);

        draft.previewItems.delete(id);
        draft.availableItems.set(id, item);
      });
    },
    [updateTableState],
  );

  /* --- Preview Table Header handlers [END] --- */

  React.useEffect(() => {
    if (_.isEmpty(availableItemsColumnFilters) && _.isEmpty(tableState.availableItemsColumnFilters)) return;

    if (_.isEqual(availableItemsColumnFilters, prevAvailableItemsColumnFilters.current)) return;

    prevAvailableItemsColumnFilters.current = availableItemsColumnFilters;

    updateTableState((draft) => {
      const internalFilters = draft.availableItemsColumnFilters.filter(({ internal }) => internal);
      draft.availableItemsColumnFilters = [...internalFilters, ...availableItemsColumnFilters];
    });
  }, [availableItemsColumnFilters, tableState.availableItemsColumnFilters, updateTableState]);

  // Available views select all button on the table header
  const getAvailableItemsTableHeaderButton = () => {
    return (
      <TableHeaderSelectAll
        handleAddAll={handleAddAllAvailableItems}
        handleClearSelection={handleClearSelectedAvailableItems}
        handleSelectAll={handleSelectAllAvailableItems}
        handleAddSelected={handleAddSelectedAvailableItems}
        isDisabled={!tableState.availableItems.size}
        hasSelectedItems={tableState.selectedAvailableRowIds.size ? true : false}
      />
    );
  };

  // Available views select all button on the table header
  const getPreviewItemsTableHeaderButton = () => {
    return (
      <PreviewTableHeader
        handleClearSelection={handleClearSelectedPreviewItems}
        handleSelectAll={handleSelectAllPreviewItems}
        handleRemoveSelected={handleRemoveSelectedPreviewItems}
        handleRemoveAll={handleRemoveAllPreviewItems}
        isDisabled={!tableState.previewItems.size}
        hasSelectedItems={tableState.selectedPreviewRowIds.size ? true : false}
      />
    );
  };

  // Selected views search bar
  const getPreviewTableHeader = () => {
    return (
      <TableHeader
        handleFilter={(e) => {
          updateTableState((draft) => {
            updateColumnFilter(
              tableColumnIds[previewItemsPrimaryColumnId],
              draft,
              ColumnFilterStateProperty.PreviewItemsColumnFilters,
              e.target.value,
              true,
            );
          });
        }}
        label={previewItemsTableLabel}
        placeholder="Search Selected"
      />
    );
  };

  // Available views search bar
  const AvailableItemsTableHeader = React.useMemo(() => {
    return (
      <TableHeader
        handleFilter={(e) => {
          updateTableState((draft) => {
            updateColumnFilter(
              tableColumnIds[availableItemsPrimaryColumnId],
              draft,
              ColumnFilterStateProperty.AvailableItemsColumnFilters,
              e.target.value,
              true,
            );
          });
        }}
        label={availableItemsTableLabel}
        placeholder={'Search Available'}
      />
    );
  }, [availableItemsPrimaryColumnId, availableItemsTableLabel, tableColumnIds, updateTableState]);

  const selectedPreviewItemIds = React.useMemo(() => {
    return [...tableState.previewItems.keys()];
  }, [tableState]);

  const availableItemIds = React.useMemo(() => {
    return [...tableState.availableItems.keys()];
  }, [tableState]);

  React.useEffect(() => {
    const updatedItems = JSON.stringify([...tableState.previewItems.values()]);

    if (prevPreviewItems.current === updatedItems) return;

    prevPreviewItems.current = updatedItems;

    onItemsChanged([...tableState.previewItems.values()]);
  }, [onItemsChanged, selectedPreviewItemIds, tableState.previewItems, tableState.availableItems, availableItemIds]);

  React.useEffect(() => {
    updateTableState((draft) => {
      const currentColumns = [...availableColumns];

      const currentPrimaryColumnCell = (item: CellContext<APLItem<T>, unknown>) =>
        cellFactory<APLItem<T>>({
          cellContentInjector,
          cellContext: item,
          className: rowClasses.row,
          clickHandler: handleAvailableItemClick,
          injectedContentOverridesText,
          text: item.row.original.name,
        });
      currentColumns[primaryAvailableColumnIndex] = {
        ...currentColumns[primaryAvailableColumnIndex],
        cell: currentPrimaryColumnCell,
      };

      draft.availableColumns = currentColumns;
    });
  }, [
    availableColumns,
    cellContentInjector,
    handleAvailableItemClick,
    injectedContentOverridesText,
    primaryAvailableColumnIndex,
    rowClasses.row,
    updateTableState,
  ]);

  React.useEffect(() => {
    updateTableState((draft) => {
      const currentColumns = [...previewColumns];

      const currentPrimaryColumnCell = (item: CellContext<APLItem<T>, unknown>) =>
        cellFactory<APLItem<T>>({
          actionIcon: <FaTrashCan className={previewClasses.icon} />,
          actionIconClickHandler: handlePreviewItemClick,
          cellContentInjector,
          cellContext: item,
          className: rowClasses.row,
          clickHandler: handlePreviewRowClick,
          injectedContentOverridesText,
          isSelected: tableState.selectedPreviewRowIds.has(item.row.original.id),
          text: item.row.original.name,
        });
      currentColumns[primaryPreviewColumnIndex] = {
        ...currentColumns[primaryPreviewColumnIndex],
        cell: currentPrimaryColumnCell,
      };

      draft.previewColumns = currentColumns;
    });
  }, [
    cellContentInjector,
    handlePreviewItemClick,
    handlePreviewRowClick,
    injectedContentOverridesText,
    previewClasses.icon,
    previewColumns,
    primaryPreviewColumnIndex,
    rowClasses.row,
    tableState.selectedPreviewRowIds,
    updateTableState,
  ]);

  // If we received new available items, update the table state, otherwise skip
  React.useEffect(() => {
    const updatedItemKeys = [...availableItems.keys()];

    if (_.isEqual(prevAvailableItemKeys.current, updatedItemKeys)) return;

    prevAvailableItemKeys.current = updatedItemKeys;

    updateTableState((draft) => {
      draft.availableItems = availableItems as Draft<ItemMap<T>>;
    });
  }, [availableItems, updateTableState]);

  // If we received new preview items, update the table state, otherwise skip
  // ToDo: Change this to use keys
  React.useEffect(() => {
    const updatedItems = JSON.stringify([...previewItems.values()]);

    if (prevPreviewItems.current === updatedItems) return;

    prevPreviewItems.current = updatedItems;

    updateTableState((draft) => {
      draft.previewItems = previewItems as Draft<ItemMap<T>>;
    });
  }, [previewItems, updateTableState]);

  React.useEffect(() => {
    if (prevPreviewItemsDesc.current !== tableState.tableOrderDesc.previewItemsDesc) {
      prevPreviewItemsDesc.current = tableState.tableOrderDesc.previewItemsDesc;
    } else {
      return;
    }

    if (overrideSorting) {
      const sortedPreviewItems = [...tableState.previewItems.values()].sort((a, b) => {
        return (
          SortingUtils.sortStrings(a['name'], b['name']) *
          (tableState.tableOrderDesc.previewItemsDesc
            ? DEFAULT_STRING_ARRAY_COMPARE_ASCENDING
            : DEFAULT_STRING_ARRAY_COMPARE_DESCENDING)
        );
      });

      onItemsChanged?.(sortedPreviewItems);
    }
  }, [overrideSorting, onItemsChanged, tableState.previewItems, tableState.tableOrderDesc.previewItemsDesc]);

  const handleSetCellColors = React.useCallback(
    (colors: CellColor) => {
      if (!colors || !tableState.selectedPreviewRowIds.size) return;

      const updatedPreviewItems = new Map(tableState.previewItems);

      tableState.selectedPreviewRowIds.forEach((id) => {
        const item = updatedPreviewItems.get(id);

        if (!item) return;

        updatedPreviewItems.set(id, {
          ...item,
          color: colors.cellColor,
          colorText: colors.textColor,
        });
      });

      onItemsChanged?.([...updatedPreviewItems.values()]);
    },
    [onItemsChanged, tableState.selectedPreviewRowIds, tableState.previewItems],
  );

  const handleDrag = React.useCallback(
    (result: unknown) => {
      updateTableState((draft) => {
        const updatedItems = new Map((result as APLItem<T>[]).map((item: APLItem<T>) => [item.id, item]));

        draft.previewItems = updatedItems as Draft<ItemMap<T>>;
      });
    },
    [updateTableState],
  );

  const selectedPreviewItems = React.useMemo(() => {
    return [...tableState.previewItems.values()].filter((item) => tableState.selectedPreviewRowIds.has(item.id));
  }, [tableState.selectedPreviewRowIds, tableState.previewItems]);

  if (!isOpen) return <></>;

  return (
    <PaginatedPreviewTable
      availableItemsTableColumnFilters={[...tableState.availableItemsColumnFilters]}
      availableItemsTableColumns={tableState.availableColumns}
      // ToDo: Lift out to props
      availableItemsTableColumnVisibility={availableColumnsVisibility}
      availableItemsTableData={[...tableState.availableItems.values()]}
      availableItemsTableHeader={AvailableItemsTableHeader}
      availableItemsTableHeaderButton={getAvailableItemsTableHeaderButton()}
      availableItemsTableInstanceRef={availableItemsTableRef}
      availableItemsTableNoDataFoundMessage={availableItemsTableNoDataFoundMessage}
      availableTableUniqueSelectedIds={tableState.selectedAvailableRowIds}
      availableItemsTableSortingState={[
        {
          desc: tableState.tableOrderDesc.availableItemsDesc,
          id: tableColumnIds[availableItemsPrimaryColumnId],
        },
      ]}
      // ToDo: Correct prop in PersonnelViewAccess
      availableRowUniqueKey={availableRowUniqueKey}
      handleSetCellColors={handleSetCellColors}
      headerSortHandlers={[
        {
          columnId: tableColumnIds[availableItemsPrimaryColumnId],
          sortHandler: () =>
            updateTableState((draft) => {
              draft.tableOrderDesc = {
                ...draft.tableOrderDesc,
                availableItemsDesc: !draft.tableOrderDesc.availableItemsDesc,
              };
            }),
        },
      ]}
      isLoading={isLoading}
      overrideTableSorting={overrideSorting}
      previewHeaderSortHandlers={[
        {
          columnId: tableColumnIds[previewItemsPrimaryColumnId],
          sortHandler: () =>
            updateTableState((draft) => {
              draft.tableOrderDesc = {
                ...draft.tableOrderDesc,
                previewItemsDesc: !draft.tableOrderDesc.previewItemsDesc,
              };
            }),
        },
      ]}
      previewRowsAreDraggable={previewRowsAreDraggable}
      previewRowUniqueKey={previewRowUniqueKey}
      previewTableColumnFilters={tableState.previewItemsColumnFilters}
      previewTableColumns={tableState.previewColumns}
      // ToDo: Lift out to props
      previewTableColumnVisibility={previewColumnsVisibility}
      previewTableData={[...tableState.previewItems.values()]}
      previewTableDragHandler={handleDrag}
      previewTableHeader={getPreviewTableHeader()}
      previewTableHeaderButton={getPreviewItemsTableHeaderButton()}
      previewItemsTableInstanceRef={previewItemsTableRef}
      previewTableSortingState={[
        {
          desc: tableState.tableOrderDesc.previewItemsDesc,
          id: tableColumnIds[previewItemsPrimaryColumnId],
        },
      ]}
      previewTableUniqueSelectedIds={tableState.selectedPreviewRowIds}
      selectedPreviewItems={selectedPreviewItems}
      showColorPicker={showColorPicker}
      showSideControls={showSideControls}
      topPosition={topPosition}
    />
  );
};

export default AvailablePreviewList;
