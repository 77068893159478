import { Alert, AlertIcon, Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';

import { ViewEditorDrawerTabIndices } from '@/constants/enums';
import { useAppDispatch } from '@/store/hooks';
import { setViewEditorAdvancedSortingRulesIsVisible, setViewEditorTabIndex } from '@/store/slices/viewEditor.slice';

const CustomOrderAlert = (): React.JSX.Element => {
  const dispatch = useAppDispatch();

  const goToAdvancedSortingRules = () => {
    dispatch(setViewEditorTabIndex(ViewEditorDrawerTabIndices.VIEW_PROPERTIES));
    dispatch(setViewEditorAdvancedSortingRulesIsVisible(true));
  };

  return (
    <Alert status="info">
      <AlertIcon />
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Text>Custom Order is applied in the View Properties tab.</Text>
        <Button onClick={goToAdvancedSortingRules}>Go there</Button>
      </HStack>
    </Alert>
  );
};

export default CustomOrderAlert;
