import { Button, HStack, Th } from '@chakra-ui/react';
import React from 'react';

import {
  DEFAULT_CLEAR_SELECTION,
  DEFAULT_REMOVE_ALL,
  DEFAULT_REMOVE_SELECTED,
  DEFAULT_SELECT_ALL,
} from '@/constants/defaults';
import { FULL_WIDTH } from '@/constants/ui';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface PreviewTableHeaderProps {
  handleClearSelection: () => void;
  handleRemoveAll: () => void;
  handleRemoveSelected: () => void;
  handleSelectAll: () => void;
  hasSelectedItems?: boolean;
  isDisabled?: boolean;
}


const PreviewTableHeader = (props: PreviewTableHeaderProps): React.JSX.Element => {
  const {
    handleClearSelection = () => void {},
    handleRemoveAll = () => void {},
    handleRemoveSelected = () => void {},
    handleSelectAll = () => void {},
    hasSelectedItems = false,
    isDisabled = false,
  } = props;

  const getButtons = (): JSX.Element => {
    // If we have selected items on the table we display "Clear Selection" and "Remove Selected" items
    // Otherwise we display just the "Remove All" button
    if (hasSelectedItems) {
      return (
        <>
          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_CLEAR_SELECTION_BUTTON}
            size="sm"
            onClick={handleClearSelection}
          >
            {DEFAULT_CLEAR_SELECTION}
          </Button>

          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_REMOVE_SELECTED_BUTTON}
            size="sm"
            onClick={handleRemoveSelected}
          >
            {DEFAULT_REMOVE_SELECTED}
          </Button>
        </>
      );
    }
    return (
      <Button
        data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_REMOVE_ALL_BUTTON}
        size="sm"
        onClick={handleRemoveAll}
        isDisabled={isDisabled}
      >
        {DEFAULT_REMOVE_ALL}
      </Button>
    );
  };

  return (
    <Th w={FULL_WIDTH}>
      <HStack w={FULL_WIDTH} justifyContent={'flex-end'}>
        <Button
          data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_SELECT_ALL_BUTTON}
          size="sm"
          onClick={handleSelectAll}
          isDisabled={isDisabled}
        >
          {DEFAULT_SELECT_ALL}
        </Button>
        {getButtons()}
      </HStack>
    </Th>
  );
};

export default PreviewTableHeader;
