import { Button, HStack, Th } from '@chakra-ui/react';
import React from 'react';

import {
  DEFAULT_ADD_ALL,
  DEFAULT_ADD_SELECTED,
  DEFAULT_CLEAR_SELECTION,
  DEFAULT_SELECT_ALL,
} from '@/constants/defaults';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface TableHeaderSelectAllProps {
  handleAddAll: () => void;
  handleAddSelected: () => void;
  handleClearSelection: () => void;
  handleSelectAll: () => void;
  hasSelectedItems?: boolean;
  isDisabled?: boolean;
}

const FULL_WIDTH = '100%';

const TableHeaderSelectAll = (props: TableHeaderSelectAllProps): React.JSX.Element => {
  const {
    handleAddAll = () => void {},
    handleAddSelected = () => void {},
    handleClearSelection = () => void {},
    handleSelectAll = () => void {},
    hasSelectedItems = false,
    isDisabled = false,
  } = props;

  const getButtons = (): JSX.Element => {
    // If we have selected items on the table we display "Clear Selection" and "Add Selected" items
    // Otherwise we display just the "Add All" button
    if (hasSelectedItems) {
      return (
        <>
          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_CLEAR_SELECTION_BUTTON}
            size="sm"
            onClick={handleClearSelection}
          >
            {DEFAULT_CLEAR_SELECTION}
          </Button>

          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_ADD_SELECTED_BUTTON}
            size="sm"
            onClick={handleAddSelected}
          >
            {DEFAULT_ADD_SELECTED}
          </Button>
        </>
      );
    }
    return (
      <Button
        data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_ADD_ALL_BUTTON}
        size="sm"
        onClick={handleAddAll}
        isDisabled={isDisabled}
      >
        {DEFAULT_ADD_ALL}
      </Button>
    );
  };

  return (
    <Th w={FULL_WIDTH}>
      <HStack w={FULL_WIDTH} justifyContent={'flex-end'}>
        <Button
          data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_SELECT_ALL_BUTTON}
          size="sm"
          onClick={handleSelectAll}
          isDisabled={isDisabled}
        >
          {DEFAULT_SELECT_ALL}
        </Button>
        {getButtons()}
      </HStack>
    </Th>
  );
};

export default TableHeaderSelectAll;
