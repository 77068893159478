import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface PreviewCellProps {
  text: string;
  actionIconClickHandler?: () => void;
  className?: string;
  color?: string | null;
  colorText?: string | null;
  icon?: React.ReactNode;
  injectedCellContent?: React.JSX.Element;
  injectedContentOverridesText?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const FULL_SIZE = '100%';

const APLTableCell = (props: PreviewCellProps): React.JSX.Element => {
  const {
    actionIconClickHandler = () => {
      console.log('Action icon click handler not defined');
    },
    className = 'APLTableCell',
    color = undefined,
    colorText = undefined,
    icon = null,
    injectedCellContent = null,
    injectedContentOverridesText = false,
    isSelected = false,
    onClick = () => {
      console.log('Click handler not defined');
    },
    text = '',
    style,
  } = props;

  const handleActionIconClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Prevent the click event from propagating to the parent element
    event.stopPropagation();
    actionIconClickHandler();
  };

  return (
    <Box className={className} w={FULL_SIZE} h={FULL_SIZE} onClick={onClick} style={style}>
      <HStack
        justifyContent={'space-between'}
        h={FULL_SIZE}
        padding={'10px'}
        // ToDo: Make a constant for the color
        backgroundColor={isSelected ? 'blue.400' : ''}
        w={'100%'}
        align={'left'}
        paddingRight={'1rem'}
        alignItems={'center'}
      >
        {injectedContentOverridesText ? (
          injectedCellContent
        ) : (
          <>
            <Text w={'95%'}>{text}</Text> {injectedCellContent}
          </>
        )}
        {(color || colorText) && (
          <Box
            w={'20px'}
            backgroundColor={color ? `${color} !important` : ' '}
            color={colorText ?? ''}
            h={'20px'}
            textAlign={'center'}
          >
            <Text>A</Text>
          </Box>
        )}
        <Box className={'row-action-icon'} onClick={handleActionIconClick}>
          {icon}
        </Box>
      </HStack>
    </Box>
  );
};

export default APLTableCell;
