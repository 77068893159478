import { Spinner } from '@chakra-ui/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';

import { store } from '@/store/store';
import { ConfigHelper } from '@/utils/env';

type Wrapper = ({ children }: { children: React.ReactNode }) => React.JSX.Element;

const LDProviderWrapper = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const passport = store.getState().auth.userPassport;
  const [ldHydrated, setLDHydrated] = React.useState<boolean>(false);

  const LDProviderRef = React.useRef<Wrapper | null>(null);

  React.useEffect(() => {
    if (passport) {
      (async () => {
        LDProviderRef.current = await asyncWithLDProvider({
          clientSideID: ConfigHelper.ldClientKey ?? '',
          context: {
            custom: {
              customerId: passport?.user.customer_id,
              empId: passport?.user.emp_id,
              isAdmin: passport?.user.is_admin,
              userId: passport?.user.user_id,
            },
            email: passport?.user.email,
            key: `${passport?.user.customer_id}_${passport?.user.emp_id}`,
            kind: 'user',
            name: passport?.user.user_name,
          },
        });

        setLDHydrated(true);
      })();
    }
  }, [passport]);

  if (!ldHydrated || !LDProviderRef.current) return <Spinner />;

  return <LDProviderRef.current>{children}</LDProviderRef.current>;
};

export default LDProviderWrapper;
